import * as React from "react"
import { SVGProps } from "react"

const TablesIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_7630_344)">
			<circle
				cx="6.6776"
				cy="17.3219"
				r="3.5233"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<circle
				cx="17.3221"
				cy="17.3219"
				r="3.5233"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<circle
				cx="6.67809"
				cy="6.67735"
				r="3.5233"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<circle
				cx="17.3221"
				cy="6.67735"
				r="3.5233"
				stroke="#7D828C"
				strokeWidth="2"
			/>
		</g>
		<defs>
			<clipPath id="clip0_7630_344">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0 -0.000244141)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default TablesIcon

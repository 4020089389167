import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"

import NoteIcon from "../../../assets/_V2/svg/NoteIcon"
import MoneyIcon from "../../../assets/_V2/svg/MoneyIcon"
import HonestIcon from "../../../assets/_V2/svg/HonestIcon"
import PriceRiseIcon from "../../../assets/_V2/svg/PriceRiseIcon"
import MenuDelIcon from "../../../assets/_V2/svg/MenuDelIcon"
import CallHelpIcon from "../../../assets/_V2/svg/CallHelpIcon"
import TablesIcon from "../../../assets/_V2/svg/TablesIcon"
import SpecialOffersIcon from "../../../assets/_V2/svg/SpecialOffersIcon"

import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			title: (
				<>
					<p className={styles.understandingFeatures__titleDesktop}>Готовые отчёты<br/>покажут</p>
					<p className={styles.understandingFeatures__titleTablet}>Готовые отчёты покажут</p>
				</>
			),
			headerPlacement: "left",
			features: [
				{
					icon: <MoneyIcon />,
					name: "Сколько денег планировать на закупки",
					description:
						"Покажет, сколько продуктов и по какой себестоимости уходит за определённый период",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-1_kz.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={96}
							/>
							<span className={styles.understandingFeatures__label}>
								Отчёт «Движение продуктов»
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Покажет, сколько продуктов и по какой себестоимости
								<br />
								уходит за определённый
								<br />
								период
							</div>
						</div>
					),
				},
				{
					icon: <HonestIcon />,
					name: "Все ли сотрудники честны",
					description:
						"Когда знаешь, сколько гостей авторизовалось — легко проверить, реальные ли это люди или фейковые аккаунты сотрудников. Станет ясно, если кто-то мошенничает.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-2_kz.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Генератор отчётов
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeBig
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Когда знаешь, сколько гостей авторизовалось
								<br />
								— легко проверить, реальные ли это люди или&nbsp;фейковые аккаунты
								сотрудников. Станет
								<br />
								ясно, если кто-то мошенничает
							</div>
						</div>
					),
				},
				{
					icon: <PriceRiseIcon />,
					name: "На что поднять цену",
					description:
						"Если себестоимость низкая, продаж много, а выручка маленькая — это повод повысить цену на блюдо.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-3_kz.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						ABC анализ
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Если себестоимость низкая, продаж много, а&nbsp;выручка маленькая —
								это повод повысить цену на блюдо
							</div>
						</div>
					),
				},
				{
					icon: <MenuDelIcon />,
					name: "Что убрать из меню",
					description:
						"Если себестоимость слишком высокая, а продаж маловато — значит, пора отказаться от блюда.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-4_kz.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						ABC анализ
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Если себестоимость слишком высокая, а&nbsp;продаж маловато
								—&nbsp;значит, пора отказаться
								<br />
								от&nbsp;блюда
							</div>
						</div>
					),
				},
				{
					icon: <CallHelpIcon />,
					name: "Когда вызывать подмогу",
					description:
						"Станет ясно, в какое время суток гостей много и стоит выводить больше сотрудников. А когда заведение пустует — запускать акцию «счастливый час».",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-5_kz.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Отчёт «По часам»
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeBig
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Станет ясно, в какое время&nbsp;суток гостей много&nbsp;и&nbsp;стоит
								выводить больше&nbsp;сотрудников. А&nbsp;когда заведение
								пустует&nbsp;—&nbsp;запускать
								<br />
								акцию «счастливый
								<br />
								час»
							</div>
						</div>
					),
				},
				{
					icon: <TablesIcon />,
					name: "Удачно ли стоят столы",
					description:
						"Узнай, какой стол приносит больше денег, и сможешь экспериментировать с расстановкой.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-6_kz.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Отчёт «По столам»
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Узнаешь, какой стол приносит больше денег, и сможешь
								экспериментировать с&nbsp;расстановкой
							</div>
						</div>
					),
				},
				{
					icon: <SpecialOffersIcon />,
					name: "Как формировать акции ",
					description:
						"Составить комбо-набор можно из самого прибыльного и наименее прибыльного блюда.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-7_kz.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Отчёт «По модификаторам»
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Составить Комбо можно из самого прибыльного и&nbsp;наименее
								прибыльного
								<br />
								блюда
							</div>
						</div>
					),
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			title: (
				<>
					<p className={styles.understandingFeatures__titleDesktop}>Жылдық есептер<br />көрсетеді</p>
					<p className={styles.understandingFeatures__titleTablet}>Жылдық есептер көрсетеді</p>
				</>
			),
			headerPlacement: "left",
			features: [
				{
					icon: <MoneyIcon />,
					name: "Сатып алуға қанша ақша жоспарлау керек",
					description:
						"Белгілі бір кезеңде қанша өнімдер және қандай өзіндік құн бойынша кететінін көрсетеді.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-1_kz.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={96}
							/>
							<span className={styles.understandingFeatures__label}>
								«Өнімдер қозғалысы» есебі
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Белгілі бір кезеңде қанша өнімдер және қандай
								өзіндік құн бойынша кететінін көрсетеді.
							</div>
						</div>
					),
				},
				{
					icon: <HonestIcon />,
					name: "Барлық қызметкерлер адал ма",
					description:
						"Қанша қонақ авторизациядан өткенін білсеңіз - бұл шынайы адамдар екенін немесе қызметкерлердің жалған аккаунттары екенін тексеру оңай. Егер біреу алдап жатса, айқын болады.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-2_kz.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Есептер генераторы
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeBig
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Қанша қонақ авторизациядан өткенін білсеңіз - бұл шынайы адамдар екенін немесе
								қызметкерлердің жалған аккаунттары екенін тексеру оңай. Егер біреу алдап жатса,
								айқын болады.
							</div>
						</div>
					),
				},
				{
					icon: <PriceRiseIcon />,
					name: "Неге бағаны көтеруге болады",
					description:
						"Егер өзіндік құн төмен болса, сату көп болады, түсім аз болса - бұл тағамның бағасын көтеруге себеп.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-3_kz.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						АВС-талдау
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Егер өзіндік құн төмен болса, сату көп болады, түсім аз болса - бұл тағамның бағасын көтеруге себеп.
							</div>
						</div>
					),
				},
				{
					icon: <MenuDelIcon />,
					name: "Мәзірден нені алып тастауға болады",
					description:
						"Егер өзіндік құн тым жоғары болса, ал сату аздау болса — демек, тағамнан бас тарту кезі келді.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-4_kz.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						АВС-талдау
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Егер өзіндік құн тым жоғары болса, ал сату аздау болса — демек, тағамнан бас тарту кезі келді.
							</div>
						</div>
					),
				},
				{
					icon: <CallHelpIcon />,
					name: "Жәрдемді қашан шақыру керек",
					description:
						"Қонақтар көп болатын және көбірек қызметкерлер шығару керек болатын тәулік уақыты анық болады. Ал мекеме бос тұрғанда - «бақытты сағат» акциясын іске қосуға болады.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-5_kz.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						«Сағат бойынша» есебі
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeBig
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Қонақтар көп болатын және көбірек қызметкерлер шығару керек болатын тәулік уақыты анық
								болады. Ал мекеме бос тұрғанда - «бақытты сағат» акциясын іске қосуға болады.
							</div>
						</div>
					),
				},
				{
					icon: <TablesIcon />,
					name: "Үстелдер дұрыс тұр ма",
					description:
						"Көбірек ақша әкелетін үстелді біліңіз, және орналастырумен тәжірибе жасай аласыз.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-6_kz.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						«Үстелдер бойынша» есебі
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Көбірек ақша әкелетін үстелді біліңіз, және орналастырумен тәжірибе жасай аласыз.
							</div>
						</div>
					),
				},
				{
					icon: <SpecialOffersIcon />,
					name: "Акцияларды қалай қалыптастыру керек",
					description:
						"Комбо-жинақты ең пайдалы және пайдасы ең аз тағамнан жасауға болады.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-7_kz.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						«Модификаторлар бойынша» есебі
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Комбо-жинақты ең пайдалы және пайдасы ең аз тағамнан жасауға болады.
							</div>
						</div>
					),
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			title: (
				<>
					<p className={styles.understandingFeatures__titleDesktop}>Готовые отчёты<br/>помогут понять</p>
					<p className={styles.understandingFeatures__titleTablet}>Готовые отчёты помогут понять</p>
				</>
			),
			headerPlacement: "left",
			features: [
				{
					icon: <MoneyIcon />,
					name: "Сколько денег выделить на закупки",
					description:
						"Покажет, сколько продуктов и по какой себестоимости уходит за определённый период.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-1_by.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Отчёт «Движение продуктов»
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Покажет, сколько продуктов и по какой себестоимости
								<br />
								уходит за определённый
								<br />
								период.
							</div>
						</div>
					),
				},
				{
					icon: <HonestIcon />,
					name: "Все ли сотрудники честны",
					description:
						"Когда знаешь, сколько гостей авторизовалось — легко проверить, реальные ли это люди или фейковые аккаунты сотрудников. Станет ясно, если кто-то мошенничает.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-2_by.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Генератор отчётов
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeBig
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Когда знаешь, сколько гостей авторизовалось
								<br />
								— легко проверить, реальные ли это люди или&nbsp;фейковые аккаунты
								сотрудников. Станет
								<br />
								ясно, если кто-то мошенничает.
							</div>
						</div>
					),
				},
				{
					icon: <PriceRiseIcon />,
					name: "На что поднять цену",
					description:
						"Если себестоимость низкая, продаж много, а выручка маленькая — это повод повысить цену на блюдо.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-3_by.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						ABC-анализ
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Если себестоимость низкая, продаж много, а&nbsp;выручка маленькая —
								это повод повысить цену на блюдо.
							</div>
						</div>
					),
				},
				{
					icon: <MenuDelIcon />,
					name: "Что убрать из меню",
					description:
						"Если себестоимость слишком высокая, а продаж маловато — значит, пора отказаться от блюда.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-4_by.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						ABC-анализ
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Если себестоимость слишком высокая, а&nbsp;продаж маловато
								—&nbsp;значит, пора отказаться
								<br />
								от&nbsp;блюда.
							</div>
						</div>
					),
				},
				{
					icon: <CallHelpIcon />,
					name: "Когда вызывать подмогу",
					description:
						"Станет ясно, в какое время суток гостей много и стоит выводить больше сотрудников. А когда заведение пустует — запускать акцию «счастливый час».",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-5_by.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Отчёт «По часам»
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeBig
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Станет ясно, в какое время&nbsp;суток гостей много&nbsp;и&nbsp;стоит
								выводить больше&nbsp;сотрудников. А&nbsp;когда заведение
								пустует&nbsp;—&nbsp;запускать
								<br />
								акцию «счастливый
								<br />
								час».
							</div>
						</div>
					),
				},
				{
					icon: <TablesIcon />,
					name: "Удачно ли стоят столы",
					description:
						"Узнай, какой стол приносит больше денег, и сможешь экспериментировать с расстановкой.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-6_by.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Отчёт «По столам»
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Узнаешь, какой стол приносит больше денег, и сможешь
								экспериментировать с&nbsp;расстановкой.
							</div>
						</div>
					),
				},
				{
					icon: <SpecialOffersIcon />,
					name: "Как формировать акции ",
					description:
						"Составить комбо-набор можно из самого прибыльного и наименее прибыльного блюда.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-understanding-7_by.png"
								alt="отчёты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Отчёт «По модификаторам»
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Составить комбо-набор можно из самого прибыльного и&nbsp;наименее
								прибыльного
								<br />
								блюда.
							</div>
						</div>
					),
				},
			],
		}
	}

	return {
		title: (
			<>
				<p className={styles.understandingFeatures__titleDesktop}>Готовые отчёты<br/>помогут понять</p>
				<p className={styles.understandingFeatures__titleTablet}>Готовые отчёты помогут понять</p>
			</>
		),
		headerPlacement: "left",
		features: [
			{
				icon: <MoneyIcon />,
				name: "Сколько денег выделить на закупки",
				description:
					"Покажет, сколько продуктов и по какой себестоимости уходит за определённый период",
				mediaContent: (
					<>
						<StaticImage
							className={styles.understandingFeatures__image}
							src="./assets/reports-understanding-1.png"
							alt="отчёты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={96}
						/>
						<span className={styles.understandingFeatures__label}>
						Отчёт «Движение продуктов»
					</span>
					</>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Покажет, сколько продуктов и по какой себестоимости
							<br />
							уходит за определённый
							<br />
							период
						</div>
					</div>
				),
			},
			{
				icon: <HonestIcon />,
				name: "Все ли сотрудники честны",
				description:
					"Когда знаешь, сколько гостей авторизовалось — легко проверить, реальные ли это люди или фейковые аккаунты сотрудников. Станет ясно, если кто-то мошенничает.",
				mediaContent: (
					<>
						<StaticImage
							className={styles.understandingFeatures__image}
							src="./assets/reports-understanding-2.png"
							alt="отчёты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={96}
						/>
						<span className={styles.understandingFeatures__label}>
						Генератор отчётов
					</span>
					</>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeBig
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Когда знаешь, сколько гостей авторизовалось
							<br />
							— легко проверить, реальные ли это люди или&nbsp;фейковые аккаунты
							сотрудников. Станет
							<br />
							ясно, если кто-то мошенничает
						</div>
					</div>
				),
			},
			{
				icon: <PriceRiseIcon />,
				name: "На что поднять цену",
				description:
					"Если себестоимость низкая, продаж много, а выручка маленькая — это повод повысить цену на блюдо.",
				mediaContent: (
					<>
						<StaticImage
							className={styles.understandingFeatures__image}
							src="./assets/reports-understanding-3.png"
							alt="отчёты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={96}
						/>
						<span className={styles.understandingFeatures__label}>
						ABC анализ
					</span>
					</>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Если себестоимость низкая, продаж много, а&nbsp;выручка маленькая —
							это повод повысить цену на блюдо
						</div>
					</div>
				),
			},
			{
				icon: <MenuDelIcon />,
				name: "Что убрать из меню",
				description:
					"Если себестоимость слишком высокая, а продаж маловато — значит, пора отказаться от блюда.",
				mediaContent: (
					<>
						<StaticImage
							className={styles.understandingFeatures__image}
							src="./assets/reports-understanding-4.png"
							alt="отчёты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={96}
						/>
						<span className={styles.understandingFeatures__label}>
						ABC анализ
					</span>
					</>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Если себестоимость слишком высокая, а&nbsp;продаж маловато
							—&nbsp;значит, пора отказаться
							<br />
							от&nbsp;блюда
						</div>
					</div>
				),
			},
			{
				icon: <CallHelpIcon />,
				name: "Когда вызывать подмогу",
				description:
					"Станет ясно, в какое время суток гостей много и стоит выводить больше сотрудников. А когда заведение пустует — запускать акцию «счастливый час».",
				mediaContent: (
					<>
						<StaticImage
							className={styles.understandingFeatures__image}
							src="./assets/reports-understanding-5.png"
							alt="отчёты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={96}
						/>
						<span className={styles.understandingFeatures__label}>
						Отчёт «По часам»
					</span>
					</>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeBig
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Станет ясно, в какое время&nbsp;суток гостей много&nbsp;и&nbsp;стоит
							выводить больше&nbsp;сотрудников. А&nbsp;когда заведение
							пустует&nbsp;—&nbsp;запускать
							<br />
							акцию «счастливый
							<br />
							час»
						</div>
					</div>
				),
			},
			{
				icon: <TablesIcon />,
				name: "Удачно ли стоят столы",
				description:
					"Узнай, какой стол приносит больше денег, и сможешь экспериментировать с расстановкой.",
				mediaContent: (
					<>
						<StaticImage
							className={styles.understandingFeatures__image}
							src="./assets/reports-understanding-6.png"
							alt="отчёты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={96}
						/>
						<span className={styles.understandingFeatures__label}>
						Отчёт «По столам»
					</span>
					</>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Узнаешь, какой стол приносит больше денег, и сможешь
							экспериментировать с&nbsp;расстановкой
						</div>
					</div>
				),
			},
			{
				icon: <SpecialOffersIcon />,
				name: "Как формировать акции ",
				description:
					"Составить комбо-набор можно из самого прибыльного и наименее прибыльного блюда.",
				mediaContent: (
					<>
						<StaticImage
							className={styles.understandingFeatures__image}
							src="./assets/reports-understanding-7.png"
							alt="отчёты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={96}
						/>
						<span className={styles.understandingFeatures__label}>
						Отчёт «По модификаторам»
					</span>
					</>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Составить Комбо можно из самого прибыльного и&nbsp;наименее
							прибыльного
							<br />
							блюда
						</div>
					</div>
				),
			},
		],
	}
}

import * as React from "react"
import { SVGProps } from "react"

const Backdrop = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="883"
		height="647"
		viewBox="0 0 883 647"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		xlinkHref="http://www.w3.org/1999/xlink"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M882.999 0H0C0 315.058 521.503 647 883 647L883 404.597L882.999 0Z"
			fill="url(#pattern0)"
			fillOpacity="0.3"
		/>
		<defs>
			<pattern
				id="pattern0"
				patternContentUnits="objectBoundingBox"
				width="0.0226501"
				height="0.0316847"
			>
				<use
					xlinkHref="#image0_7740_167317"
					transform="scale(0.000566251 0.000772798)"
				/>
			</pattern>
			<image
				id="image0_7740_167317"
				width="40"
				height="41"
				xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAgklEQVRYCe3T4QmAIBCG4QbQWzk30LMJ6tqvEQqpwP75S4Re4UBE8fNRp4mGAAIIIIAAAggggAACgwjIYrNXO0pJ3uMgse4Ykrfo1c66RNcwTMhH7hOwjBGwVUDUQn29pe+ipdb1XeY5tfR+EhdtnPfX5fRsggACCCCAAAIIIPBLgQuMFkPGdY5+vgAAAABJRU5ErkJggg=="
			/>
		</defs>
	</svg>
)
export default Backdrop

import * as React from "react"
import { SVGProps } from "react"

const HomeIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M3 12L12 3L21 12"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.99999 13.2506L5.99999 21L18 21L18 13.2506"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.3211 13.1782L9.67188 17.8275"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle
			cx="9.67188"
			cy="13.1783"
			r="1.2"
			transform="rotate(45 9.67188 13.1783)"
			fill="#7D828C"
		/>
		<circle
			cx="14.3213"
			cy="17.8274"
			r="1.2"
			transform="rotate(45 14.3213 17.8274)"
			fill="#7D828C"
		/>
	</svg>
)

export default HomeIcon

import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import mediaContent from "./mediaContent.module.scss";
import { pagesLinks } from "../../../pages-data/_V2/common/links";
import { Locales } from "../../../localization/types";
import classNames from "classnames";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 460,
					tabName: "Для сетей",
					desktopTitle: "Расширяй дело",
					desktopContent: (
						<>
							Из&nbsp;одного аккаунта можно управлять любым количеством заведений. У&nbsp;каждого&nbsp;&mdash; свой
							складской учёт и&nbsp;своё меню, но&nbsp;вся информация собирается в&nbsp;одном месте
						</>
					),
					mobileContent: (
						<>
							Из&nbsp;одного аккаунта можно управлять любым количеством заведений. У&nbsp;каждого&nbsp;&mdash; свой
							складской учёт и&nbsp;своё меню, но вся&nbsp;информация собирается в&nbsp;одном месте.
						</>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/reports-purpose-1_kz.webp"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					// detailText: "Подробнее",
					// detailLink: pagesLinks.franchise.href,
				},
				{
					desktopContentWidth: 460,
					tabName: "Для франшиз",
					desktopTitle: "Держи партнёров \nна виду",
					desktopContent: (
						<>
							Единый стандарт отчётов для всех франчайзи&nbsp;&mdash; не&nbsp;нужно возиться с&nbsp;документами разных
							форматов. Все отчёты доступны владельцу франшизы.
						</>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/reports-purpose-2_kz.webp"
							alt="кухонный дисплей для кафе"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					// detailText: "Подробнее",
					// detailLink: pagesLinks.franchise.href,
				},
			],
		};
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 460,
					tabName: "Желілер үшін",
					desktopTitle: "Істі кеңейтіңіз",
					desktopContent: (
						<>
							Бір аккаунттан мекемелердің кез келген санын басқаруға болады. Әрқайсысында - өз қоймалық есебі және өз мәзірі, бірақ бүкіл ақпарат бір жерде жиналады.
						</>
					),
					mobileContent: (
						<>
							Бір аккаунттан мекемелердің кез келген санын басқаруға болады. Әрқайсысында - өз қоймалық есебі және өз мәзірі, бірақ бүкіл ақпарат бір жерде жиналады.
						</>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/reports-purpose-1_kz.webp"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					// detailText: "Подробнее",
					// detailLink: pagesLinks.franchise.href,
				},
				{
					desktopContentWidth: 460,
					tabName: "Франшизалар үшін",
					desktopTitle: "Серіктестерді көз\nтүсетін жерде ұстаңыз",
					desktopContent: (
						<>
							Барлық франчайзилер үшін бірыңғай есептер стандарты - әртүрлі форматтағы құжаттармен әуреленудің керегі жоқ.  Барлық есептер франшиза иесіне қолжетімді.
						</>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/reports-purpose-2_kz.webp"
							alt="кухонный дисплей для кафе"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					// detailText: "Подробнее",
					// detailLink: pagesLinks.franchise.href,
				},
			],
		};
	}

	if (locale === "ru-BY") {
		return {
			features: [
				{
					desktopContentWidth: 460,
					tabName: "Для сетей",
					desktopTitle: "Расширяй дело",
					desktopContent: (
						<>
							Из&nbsp;одного аккаунта можно управлять любым количеством заведений. У&nbsp;каждого&nbsp;&mdash; свой
							складской учёт и&nbsp;своё меню, но&nbsp;вся информация собирается в&nbsp;одном месте
						</>
					),
					mobileContent: (
						<>
							Из&nbsp;одного аккаунта можно управлять любым количеством заведений. У&nbsp;каждого&nbsp;&mdash; свой
							складской учёт и&nbsp;своё меню, но&nbsp;вся информация собирается в&nbsp;одном месте.
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image__by)}
							src="./assets/reports-purpose-1_by.webp"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 460,
					tabName: "Для франшиз",
					desktopTitle: "Держи партнёров \nна виду",
					tabletTitle: "Держи партнёров на виду",
					mobileTitle: "Держи \nпартнёров \nна виду",
					desktopContent: (
						<>
							Единый стандарт отчётов для всех франчайзи, не&nbsp;нужно возиться с&nbsp;документами разных форматов. Все
							отчёты доступны владельцу франшизы.
						</>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/reports-purpose-2_by.webp"
							alt="кухонный дисплей для кафе"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		};
	}

	return {
		features: [
			{
				desktopContentWidth: 460,
				tabName: "Для сетей",
				desktopTitle: "Расширяй дело",
				desktopContent: (
					<>
						Из&nbsp;одного аккаунта можно управлять любым количеством точек.
						У&nbsp;каждой&nbsp;&mdash; свой складской учёт и&nbsp;своё меню,
						но&nbsp;вся информация собирается в&nbsp;одном месте.
						<br />
						<br />
						Программа хранит контакты гостей, историю операций и&nbsp;накопления
						бонусов. Превращай новых гостей в&nbsp;постоянных с&nbsp;электронными
						картами лояльности и&nbsp;бонусными баллами.
					</>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/reports-purpose-1.png"
						alt="отчеты по работе поваров"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				detailText: "Подробнее",
				detailLink: pagesLinks.franchise.href,
			},
			{
				desktopContentWidth: 460,
				tabName: "Для франшиз",
				desktopTitle: "Контролируй партнёров",
				desktopContent: (
					<>
						Не&nbsp;нужно возиться с&nbsp;документами разных форматов: есть единый
						стандарт отчётов для франчайзи. Вся аналитика кафе доступна владельцу франшизы в одной системе.
					</>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/reports-purpose-2.png"
						alt="кухонный дисплей для кафе"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				detailText: "Подробнее",
				detailLink: pagesLinks.franchise.href,
			},
		],
	};
};

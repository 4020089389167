import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import ProductPresentation from '../pagesSections/reports/ProductPresentation'
import Advantages from '../components/_V2/Advantages'
import BusinessTypes from '../components/_V2/BusinessTypes'

import AdditionalServices from '../pagesSections/index/AdditionalServices'
import CtaForm from '../pagesSections/index/CtaForm'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import { getReportstMeta } from '../pagesSections/reports/meta'

import { getAdvantages } from '../pages-data/_V2/reports/advantages'
import { businessTypes as getBusinessTypes } from '../pages-data/_V2/common/business-types-compact'
import { getFeaturesData } from '../pages-data/_V2/reports/features'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/reports.module.scss'
import PurposeFeatures from '../pagesSections/reports/PurposeFeatures'
import UnderstandingFeatures from '../pagesSections/reports/UnderstandingFeatures'
import SimpleFeatures from '../components/_V2/SimpleFeatures'
import DataFeatures from '../pagesSections/reports/DataFeatures'
import WidgetsFeatures from '../pagesSections/reports/WidgetsFeatures'
import ReportsFeatures from '../pagesSections/reports/ReportsFeatures'

import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function PageLine() {
  const localizationContext = useLocalizationContext()
  const metaInfo = getReportstMeta(localizationContext.locale)

  const features = getFeaturesData(localizationContext.locale)
  const advantages = getAdvantages(localizationContext.locale)
  const businessTypes = getBusinessTypes(localizationContext.locale)

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={metaInfo.title}
      metaTags={metaInfo.metaTags}
      linkCanonical={metaInfo.canonical}
      mainWrapperClassName={pageStyles.pageWrapper}
      footerClassName={pageStyles.pageSection}
      schemaType="Product"
      schemaName={metaInfo.title}
      schemaDescription={metaInfo.metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaInfo.metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <ProductPresentation />

      <Advantages
        className={classNames(
				  styles.reports__advantages,
				  pageStyles.pageSection,
        )}
        items={advantages}
      />

      <WidgetsFeatures className={pageStyles.pageSection} />

      <UnderstandingFeatures className={pageStyles.pageSection} />

      <SimpleFeatures
        data={features.filter((feature, index) => index === 0)}
        className={pageStyles.pageSection}
      />

      <ReportsFeatures className={pageStyles.pageSection} />

      <PurposeFeatures className={pageStyles.pageSection} />

      <DataFeatures className={pageStyles.pageSection} />

      <SimpleFeatures
        data={features.filter((feature, index) => index > 0)}
        className={classNames(pageStyles.pageSection, {[pageStyles.reports_by]: localizationContext.locale !== 'ru-RU'}, {[pageStyles.reports_ru]: localizationContext.locale === 'ru-RU'})}
      />

      <AdditionalServices className={pageStyles.pageSection} />

      <CtaForm isFry={false} className={pageStyles.pageSection} />

      <BusinessTypes key={localizationContext.locale} data={businessTypes} className={pageStyles.pageSection} />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
    </DefaultLayout>
  )
}

import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import styles from "./styles.module.scss";
import classNames from "classnames";
import { externalLinks, pagesLinks } from "../common/links";

import AppStore from "../../../assets/svg/_V2/AppStore";
import GooglePlay from "../../../assets/svg/_V2/GooglePlay";
import { Locales } from "../../../localization/types";

export const getFeaturesData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return [
			{
				category: "Генератор отчётов",
				title: "Ответ \nна нестандартный \nзапрос",
				description: (
					<p>
						Посмотреть чеки официантки Кати, оплаченные банковской картой, по&nbsp;акции &laquo;1+1&raquo;,
						во&nbsp;втором зале, ещё и за прошлый месяц? Не&nbsp;проблема!
					</p>
				),
				descClass: styles.feature1_kz__one,
				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/reports-feature-1_kz.webp"
						alt="программа автоматизации фастфуда"
						breakpoints={[440, 740, 920, 630]}
						sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 630px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.feature1,
				theme: "primary",
			},
			{
				category: "quick resto manager",
				title: "Весь бизнес \nв приложении",
				mobileTitle: "Бизнес у тебя \nв кармане",
				actionClass: styles.btn__by,
				description: (
					<>
						<p className={classNames(styles.feature1_kz__one, styles.advantagesMobile__one)}>
							Приложение Quick Resto Manager&nbsp;&mdash; это удобное управление
							заведением со&nbsp;смартфона:
						</p>
						<noindex className={styles.advantagesMobile}>
							<p>
								Бесплатное приложение Quick Resto Manager&nbsp;&mdash; это удобное управление заведением
								со смартфона:
							</p>
						</noindex>
						<ul>
							<li className={styles.advantagesMobile__one}>
								мгновенная информация по&nbsp;наличным в&nbsp;кассе, выручке,
								среднему чеку и&nbsp;количеству гостей
							</li>
							<li className={styles.advantagesMobile}>
								<noindex>
									мгновенная информация по наличным&nbsp;в&nbsp;кассе, выручке, среднему чеку и&nbsp;количеству гостей
								</noindex>
							</li>
							<li>
								наглядные графики и&nbsp;сигналы о&nbsp;сомнительных операциях
							</li>
						</ul>
					</>
				),
				bottomContent: (
					<div className={styles.notes}>
						<a
							href={externalLinks.appstoreQRMRoistat}
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<AppStore />
						</a>
						<a
							href={externalLinks.googlePlayQRM}
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<GooglePlay />
						</a>
					</div>
				),
				img: (
					<div className={styles.image2__container}>
						<StaticImage
							className={classNames(styles.image2__kz, styles.image2__by)}
							src="./assets/reports-feature-2_kz.webp"
							alt="замена тикет-принтера"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				simpleAction: true,
				reverse: true,
				className: styles.feature2,
				theme: "green",

				actionText: "Подробнее",
				actionLink: pagesLinks.indexQRM.href,
			},
		];
	}

	if (locale === "kz-KZ") {
		return [
			{
				category: "Есептер генераторы",
				title: "Әдеттен\nтыс сұрауға\nжауап беру",
				description: (
					<p>
						Даяшы Катяның өткен айдағы, екінші залдағы, «1+1» акциясы бойынша банк картасымен төленген чектерін қарап шығу керек пе? Еш қиындығы жоқ!
					</p>
				),
				descClass: styles.feature1_kz__one,
				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/reports-feature-1_kz.webp"
						alt="программа автоматизации фастфуда"
						breakpoints={[440, 740, 920, 630]}
						sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 630px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.feature1,
				theme: "primary",
			},
			{
				category: "quick resto manager",
				title: "Бүкіл бизнес\nқосымшада",
				mobileTitle: "Бүкіл бизнес\nқосымшада",
				actionClass: styles.btn__by,
				description: (
					<>
						<p className={classNames(styles.feature1_kz__one, styles.advantagesMobile__one)}>
							Quick Resto Manager қосымшасы — бұл смартфоннан мекемені ыңғайлы басқару:
						</p>
						<noindex className={styles.advantagesMobile}>
							<p>
								Quick Resto Manager қосымшасы — бұл смартфоннан мекемені ыңғайлы басқару:
							</p>
						</noindex>
						<ul>
							<li className={styles.advantagesMobile__one}>
								кассадағы қолма-қол ақша, түсім, орташа чек және қонақтар саны бойынша лездік ақпарат
							</li>
							<li className={styles.advantagesMobile}>
								<noindex>
									кассадағы қолма-қол ақша, түсім, орташа чек және қонақтар саны бойынша лездік ақпарат
								</noindex>
							</li>
							<li>
								көрнекі графиктер және күмәнді операциялар туралы сигналдар
							</li>
						</ul>
					</>
				),
				bottomContent: (
					<div className={styles.notes}>
						<a
							href={externalLinks.appstoreQRMRoistat}
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<AppStore />
						</a>
						<a
							href={externalLinks.googlePlayQRM}
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<GooglePlay />
						</a>
					</div>
				),
				img: (
					<div className={styles.image2__container}>
						<StaticImage
							className={classNames(styles.image2__kz, styles.image2__by)}
							src="./assets/reports-feature-2_kz.webp"
							alt="замена тикет-принтера"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				simpleAction: true,
				reverse: true,
				className: styles.feature2,
				theme: "green",

				actionText: "Толығырақ",
				actionLink: pagesLinks.indexQRM.href,
			},
		];
	}

	if (locale === "ru-BY") {
		return [
			{
				category: "Генератор отчётов",
				title: "Даже если запрос нестандартный",
				description: (
					<p>
						Посмотреть чеки официантки Кати, оплаченные банковской картой, по&nbsp;акции &laquo;1+1&raquo;,
						во&nbsp;втором зале, ещё&nbsp;и за прошлый месяц? Не&nbsp;проблема!
					</p>
				),
				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/reports-feature-1_by.webp"
						alt="программа автоматизации фастфуда"
						breakpoints={[440, 740, 920, 630]}
						sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 630px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.feature1,
				theme: "primary",
			},
			{
				category: "quick resto manager",
				title: "Весь бизнес \nв приложении",
				mobileTitle: "Весь бизнес \nв приложении",
				actionClass: styles.btn__by,
				description: (
					<>
						<p className={classNames(styles.feature1_kz__one, styles.advantagesMobile__one)}>
							Приложение Quick Resto Manager&nbsp;&mdash; это удобное управление
							заведением со&nbsp;смартфона:
						</p>
						<noindex className={styles.advantagesMobile}>
							<p>
								Бесплатное приложение Quick Resto Manager&nbsp;&mdash; это удобное управление заведением
								со смартфона:
							</p>
						</noindex>
						<ul>
							<li className={styles.advantagesMobile__one}>
								мгновенная информация по&nbsp;наличным в&nbsp;кассе, выручке,
								среднему чеку и&nbsp;количеству гостей
							</li>
							<li className={styles.advantagesMobile}>
								<noindex>
									мгновенная информация по наличным&nbsp;в&nbsp;кассе, выручке, среднему чеку и&nbsp;количеству гостей
								</noindex>
							</li>
							<li>
								наглядные графики и&nbsp;сигналы о&nbsp;сомнительных операциях
							</li>
						</ul>
					</>
				),
				bottomContent: (
					<div className={styles.notes}>
						<a
							href={externalLinks.appstoreQRMRoistat}
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<AppStore />
						</a>
						<a
							href={externalLinks.googlePlayQRM}
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<GooglePlay />
						</a>
					</div>
				),
				img: (
					<div className={styles.image2__container}>
						<StaticImage
							className={classNames(styles.image2__kz, styles.image2__by)}
							src="./assets/reports-feature-2_by.webp"
							alt="замена тикет-принтера"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				simpleAction: true,
				reverse: true,
				className: styles.feature2,
				theme: "green",

				actionText: "Подробнее",
				actionLink: pagesLinks.indexQRM.href,
			},
		];
	}

	return [
		{
			category: "Генератор отчётов",
			title: "Даже если запрос нестандартный",
			description: (
				<p>
					Посмотреть чеки официантки Кати, оплаченные банковской картой,
					по&nbsp;акции &laquo;1+1&raquo;, во&nbsp;втором зале, ещё
					и&nbsp;за&nbsp;прошлый месяц? С&nbsp;аналитикой кофейни&nbsp;&mdash;
					не&nbsp;проблема!
				</p>
			),
			img: (
				<StaticImage
					className={styles.image1}
					src="./assets/reports-feature-1.png"
					alt="программа автоматизации фастфуда"
					breakpoints={[440, 740, 920, 630]}
					sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 630px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			className: styles.feature1,
			theme: "primary",
		},
		{
			category: "quick resto manager",
			title: "Бизнес у тебя в кармане",
			actionClass: styles.btn__ru,
			description: (
				<>
					<p>
						Приложение Quick Resto Manager&nbsp;&mdash; это удобное управление
						заведением со&nbsp;смартфона:
					</p>
					<ul>
						<li>
							мгновенная информация по наличным в кассе, выручке, среднему чеку и количеству гостей
						</li>
						<li>
							наглядные графики на основе аналитики ресторана
						</li>
						<li>
							сигналы о сомнительных операциях
						</li>
					</ul>
				</>
			),
			bottomContent: (
				<div className={styles.notes}>
					<a
						href={externalLinks.appstoreQRMRoistat}
						className={classNames(styles.note, styles.link)}
						target="_blank"
					>
						<AppStore />
					</a>
					<a
						href={externalLinks.googlePlayQRM}
						className={classNames(styles.note, styles.link)}
						target="_blank"
					>
						<GooglePlay />
					</a>
				</div>
			),
			img: (
				<div className={styles.image2__container}>
					<StaticImage
						className={classNames(styles.image2, styles.image2__ru)}
						src="./assets/reports-feature-2.png"
						alt="замена тикет-принтера"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</div>
			),
			simpleAction: true,
			reverse: true,
			className: styles.feature2,
			theme: "green",

			actionText: "Подробнее",
			actionLink: pagesLinks.indexQRM.href,
		},
	];
};

import * as React from "react"
import { SVGProps } from "react"

const DishIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.9277 14.9734L10.1777 18.7234"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.1777 14.9734L13.9277 18.7234"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3 16.8486H7.5M21 16.8486H16.5"
			stroke="#7D828C"
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
		/>
		<path
			d="M4.28516 16.8483C4.28516 14.8024 5.09792 12.8402 6.54463 11.3935C7.99133 9.94679 9.95349 9.13403 11.9994 9.13403C14.0454 9.13403 16.0075 9.94679 17.4543 11.3935C18.901 12.8402 19.7137 14.8024 19.7137 16.8483"
			stroke="#7D828C"
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
		/>
		<path
			d="M12.0008 9.13402C13.066 9.13402 13.9294 8.27056 13.9294 7.20544C13.9294 6.14031 13.066 5.27686 12.0008 5.27686C10.9357 5.27686 10.0723 6.14031 10.0723 7.20544C10.0723 8.27056 10.9357 9.13402 12.0008 9.13402Z"
			stroke="#7D828C"
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
		/>
	</svg>
)

export default DishIcon

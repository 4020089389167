import React from "react"

import TabsFeatures, { HIDE_BADGES } from "../../../components/_V2/TabsFeatures"
import { getData } from "./data"
import styles from "./styles.module.scss"
import { useLocalizationContext } from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function ReportsFeatures(props: Props) {
	const localizationContext = useLocalizationContext()

	return (
		<TabsFeatures
			key={localizationContext.locale}
			data={getData(localizationContext.locale)}
			className={props.className}
			rootClass={styles.understandingFeatures}
			containerClass={styles.understandingFeatures__container}
			wrapperClass={styles.understandingFeatures__wrapper}
			additionalFeaturesListClass={styles.understandingFeatures__featuresList}
			leftColumnClass={styles.understandingFeatures__leftColumn}
			sliderClass={styles.understandingFeatures__swiper}
			theme={"cyan"}
			hideBadges={HIDE_BADGES.MOBILE}
		/>
	)
}

import * as React from "react"
import { SVGProps } from "react"

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x="20.1582"
			y="20.4998"
			width="17"
			height="17"
			rx="3"
			transform="rotate(-180 20.1582 20.4998)"
			stroke="#7D828C"
			strokeWidth="2"
		/>
		<circle cx="7.6582" cy="9.00171" r="1" fill="#7D828C" />
		<path
			d="M7.6582 4.99976V1.99976"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M15.6582 4.99976V1.99976"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<circle cx="7.6582" cy="12.9998" r="1" fill="#7D828C" />
		<circle cx="7.6582" cy="16.9978" r="1" fill="#7D828C" />
		<circle cx="11.6582" cy="9.00171" r="1" fill="#7D828C" />
		<circle cx="11.6582" cy="12.9998" r="1" fill="#7D828C" />
		<circle cx="11.6582" cy="16.9978" r="1" fill="#7D828C" />
		<circle cx="15.6582" cy="9.00171" r="1" fill="#7D828C" />
		<circle cx="15.6582" cy="12.9998" r="1" fill="#7D828C" />
		<circle cx="15.6582" cy="16.9978" r="1" fill="#7D828C" />
	</svg>
)

export default CalendarIcon

import * as React from "react"
import { SVGProps } from "react"

const SpecialOffersIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_7049_53036)">
			<path
				d="M4 4.49976C4 3.39519 4.89543 2.49976 6 2.49976H18C19.1046 2.49976 20 3.39519 20 4.49976V18.2637C20 19.7505 18.4354 20.7174 17.1056 20.0525L12.8944 17.947C12.3314 17.6654 11.6686 17.6654 11.1056 17.947L6.89443 20.0525C5.56462 20.7174 4 19.7505 4 18.2637V4.49976Z"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<path
				d="M11.5487 6.29716C11.7297 5.91787 12.2696 5.91788 12.4506 6.29716L13.3274 8.13457C13.4002 8.28721 13.5453 8.39264 13.713 8.41475L15.7314 8.68081C16.1481 8.73573 16.3149 9.24919 16.0101 9.53853L14.5336 10.9402C14.4109 11.0566 14.3555 11.2272 14.3863 11.3935L14.7569 13.3954C14.8335 13.8086 14.3967 14.1259 14.0273 13.9255L12.238 12.9543C12.0894 12.8736 11.91 12.8736 11.7613 12.9543L9.97201 13.9255C9.60265 14.1259 9.16588 13.8086 9.24239 13.3954L9.61308 11.3935C9.64387 11.2272 9.58844 11.0566 9.46578 10.9402L7.98923 9.53852C7.68444 9.24919 7.85128 8.73573 8.26792 8.68081L10.2863 8.41475C10.454 8.39264 10.5991 8.28721 10.672 8.13457L11.5487 6.29716Z"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_7049_53036">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0 -0.000244141)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default SpecialOffersIcon

import * as React from "react"
import { SVGProps } from "react"

const MenuDelIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_7630_5009)">
			<rect
				x="19"
				y="4.24976"
				width="17"
				height="14"
				rx="1"
				transform="rotate(90 19 4.24976)"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<path
				d="M12 5.74976L12 2.74976"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M16 5.74976L16 2.74976"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M8 5.74976L8 2.74976"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M8.92578 10.2949L15.074 16.4432"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.0741 10.2949L8.92587 16.4432"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_7630_5009">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0 -0.000244141)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default MenuDelIcon

import React from "react"

import FullScreenFeatures from "../../../components/_V2/FullScreenFeatures"

import styles from './styles.module.scss'
import { getData } from "./data"
import { useLocalizationContext } from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function DataFeatures(props: Props) {
	const localizationContext = useLocalizationContext()
	const data = getData(localizationContext.locale)

	return (
		<FullScreenFeatures
			key={localizationContext.locale}
			className={props.className}
			data={data.features}
			featureContainer={styles.featureContainer}
			theme={"fuchsia"}
		/>
	)
}

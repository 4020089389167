import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import { pagesLinks, pagesLinksKz } from "../../../pages-data/_V2/common/links";
import styles from "./styles.module.scss";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			pageName: "Отчёты и аналитика",
			desktopTitle: "Числа правят миром",
			subTitle: (
				<>
					<p className={styles.banner_kz__desktop}>
						Особенно когда они всегда под рукой. <br />
						Есть как наглядные виджеты для оперативного среза, <br />
						так и&nbsp;детальные отчёты: поможем принять <br />
						правильное решение на&nbsp;любой стадии <br />
						бизнеса и&nbsp;повысить продажи.
					</p>
					<noindex className={styles.banner_kz__tablet}>
						<p>
							Особенно когда они всегда под рукой. Есть как наглядные виджеты для оперативного среза, так
							и&nbsp;детальные отчёты: поможем принять правильное решение на&nbsp;любой стадии бизнеса и&nbsp;повысить
							продажи.
						</p>
					</noindex>
					<noindex className={styles.banner_kz__mobile}>
						<p>
							Особенно когда они всегда под рукой.
							Есть как наглядные виджеты для оперативного среза, так и&nbsp;детальные отчёты: поможем принять правильное
							решение на&nbsp;любой стадии бизнеса и&nbsp;повысить продажи.
						</p>
					</noindex>
				</>
			),
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/reports-presentation_kz.webp"
					alt="отчеты и аналитика"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={90}
				/>
			),
			cta: pagesLinks.registration.text,
			ctaLink: pagesLinks.registration,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
		};
	}

	if (locale === "kz-KZ") {
		return {
			pageName: "Есептер мен аналитика",
			desktopTitle: "Сандар әлемді басқарады",
			subTitle: (
				<>
					<p className={styles.banner_kz__desktop}>
						Әсіресе олар әрдайы қол астында болғанда.<br/>
						Жедел талдау үшін көрнекі виджеттер де,<br/>
						егжей-тегжейлі есептер де бар: бизнестәің кез келген сатысында дұрыс шешім қабылдауға және
						сатуды арттыруға көмектесеміз.
					</p>
					<noindex className={styles.banner_kz__tablet}>
						<p>
							Әсіресе олар әрдайы қол астында болғанда.
							Жедел талдау үшін көрнекі виджеттер де,
							егжей-тегжейлі есептер де бар: бизнестәің кез келген сатысында дұрыс шешім қабылдауға және сатуды арттыруға көмектесеміз.
						</p>
					</noindex>
					<noindex className={styles.banner_kz__mobile}>
						<p>
							Әсіресе олар әрдайы қол астында болғанда.
							Жедел талдау үшін көрнекі виджеттер де,
							егжей-тегжейлі есептер де бар: бизнестәің кез келген сатысында дұрыс шешім қабылдауға және сатуды арттыруға көмектесеміз.
						</p>
					</noindex>
				</>
			),
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/reports-presentation_kz.webp"
					alt="отчеты и аналитика"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={90}
				/>
			),
			cta: pagesLinksKz.registration.text,
			ctaLink: pagesLinks.registration,
			desktopCtaDetails:
				"Тез, ыңғайлы және екі апта тегін ",
		};
	}

	if (locale === "ru-BY") {
		return {
			pageName: "Отчёты и аналитика",
			desktopTitle: "Числа на твоей стороне",
			subTitle: (
				<>
					<p className={styles.banner_kz__desktop}>
						Особенно когда они всегда под рукой. <br />
						Есть как наглядные виджеты для оперативного среза, <br />
						так и&nbsp;детальные отчёты: поможем принять <br />
						правильное решение на&nbsp;любой стадии бизнеса <br />
						и&nbsp;повысить продажи.
					</p>
					<noindex className={styles.banner_kz__tablet}>
						<p>
							Особенно когда они всегда под рукой. Есть как наглядные виджеты для оперативного среза, <br />
							так и&nbsp;детальные отчёты: поможем принять правильное решение на&nbsp;любой стадии бизнеса и&nbsp;повысить продажи.
						</p>
					</noindex>
					<noindex className={styles.banner_kz__mobile}>
						<p>
							Особенно когда они всегда под рукой.
							Есть как наглядные виджеты для оперативного среза, так и&nbsp;детальные отчёты: поможем принять правильное
							решение на&nbsp;любой стадии бизнеса и&nbsp;повысить продажи.
						</p>
					</noindex>
				</>
			),
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/reports-presentation_by.webp"
					alt="отчеты и аналитика"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={90}
				/>
			),
			cta: pagesLinks.registration.text,
			ctaLink: pagesLinks.registration,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
		};
	}

	return {
		pageName: "Отчёты и аналитика",
		desktopTitle: "Числа правят миром",
		subTitle: (
			<p>
				Особенно когда они всегда под рукой. Есть как наглядные виджеты для
				оперативного среза, так и&nbsp;детальные отчёты&nbsp;&mdash; поможем
				принять правильное решение на&nbsp;любой стадии бизнеса и&nbsp;повысить
				продажи.
			</p>
		),
		figure: (
			<StaticImage
				className={styles.image}
				src="./assets/reports-presentation.png"
				alt="отчеты и аналитика"
				objectFit="contain"
				objectPosition="center"
				placeholder="blurred"
				quality={90}
			/>
		),
		cta: pagesLinks.registration.text,
		ctaLink: pagesLinks.registration,
		desktopCtaDetails:
			"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две \nнедели бесплатно.",
	};
};

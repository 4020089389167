import * as React from "react"
import { SVGProps } from "react"

const PlanIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x="21"
			y="3"
			width="18"
			height="18"
			rx="1"
			transform="rotate(90 21 3)"
			stroke="#7D828C"
			strokeWidth="2"
		/>
		<path
			d="M9.54492 3V21"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20.1816 8.72729L6.27982 8.72729"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20.1816 15.2727L6.27982 15.2727"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default PlanIcon

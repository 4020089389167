import * as React from "react"
import { SVGProps } from "react"

const HandIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M10.6776 17.7154C11.1664 17.7692 11.6631 17.7968 12.1662 17.7968C14.3817 17.7968 16.4721 17.2614 18.3152 16.3129C19.1286 15.8944 20.1764 16.258 20.4357 17.1352C20.6826 17.9705 20.3619 18.8739 19.5794 19.2563C17.9199 20.0673 15.2222 21 12.1662 21C8.11152 21 4.68756 19.3782 3.48242 18.5673"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3.48242 12.8296C4.72382 11.8754 5.94397 12.5711 6.37702 12.8296C8.02552 13.956 10.0189 14.6148 12.1662 14.6148C12.6417 14.6148 13.1096 14.5825 13.5678 14.52C14.3847 14.4085 15.2068 14.8897 15.3778 15.6962C15.5742 16.6224 15.0451 17.5368 14.1064 17.6607"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M9.5625 5.60425C9.5625 4.16596 10.7285 3 12.1668 3C13.605 3 14.771 4.16596 14.771 5.60425C14.771 7.04254 13.605 8.20851 12.1668 8.20851V9.51064"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle cx="12.166" cy="12" r="1" fill="#7D828C" />
	</svg>
)

export default HandIcon

import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types";
import classNames from "classnames";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					tabName: "Данные по персоналу",
					desktopTitle: "Кто не работает — тот в отчёт не попадает",
					desktopContent:
						"Учитывается рабочее время и эффективность каждого сотрудника.\n Зарплату можно рассчитать автоматически.",
					detailLink: pagesLinks.staff,
					mediaContent: (
						<div className={classNames(styles.image__container, styles.image__container_by)}>
							<StaticImage
								className={classNames(styles.image1, styles.image1__by)}
								src="./assets/reports-data-1_kz.webp"
								alt={"Доставка для ресторанного бизнеса"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
				{
					tabName: "Уведомления",
					desktopTitle: "Больше знаешь — выше продажи",
					desktopContent:
						"Первым узнавай о самом важном: например, выручке за смену или отменах.\n По электронной почте, в мобильном приложении или прямо в Telegram.",
					detailLink: pagesLinks.notifications,
					mediaContent: (
						<div className={classNames(styles.image__container, styles.image__container_by)}>
							<StaticImage
								className={classNames(styles.image2, styles.image2__by)}
								src="./assets/reports-data-2-1_kz.webp"
								alt={"Карта столов для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={styles.image3}
								src="./assets/reports-data-2-2_kz.webp"
								alt={"Карта столов для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
			] as Array<{
				tabName: string

				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent: JSX.Element
			}>,
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					tabName: "Персонал бойынша деректер",
					desktopTitle: "Кім жұмыс істемейді - сол есепке түспейді",
					desktopContent:
						"Әр қызметкердің жұмыс уақыты мен тиімділігі есепке алынады. Жалақыны автоматты түрде есептеуге болады",
					detailLink: pagesLinks.staff,
					mediaContent: (
						<div className={classNames(styles.image__container, styles.image__container_by)}>
							<StaticImage
								className={classNames(styles.image1, styles.image1__by)}
								src="./assets/reports-data-1_kz.webp"
								alt={"Доставка для ресторанного бизнеса"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
				{
					tabName: "Хабарламалар",
					desktopTitle: "Көбірек білсеңіз - сату жоғары болады",
					desktopContent:
						"Ең маңызды: мысалы, ауысым түсімі немесе бас тартулар туралы бірінші болып біліңіз.\nЭлектрондық пошта арқылы, мобильді қосымшада немесе тікелей Telegram-да.",
					detailLink: pagesLinks.notifications,
					mediaContent: (
						<div className={classNames(styles.image__container, styles.image__container_by)}>
							<StaticImage
								className={classNames(styles.image2, styles.image2__by)}
								src="./assets/reports-data-2-1_kz.webp"
								alt={"Карта столов для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={styles.image3}
								src="./assets/reports-data-2-2_kz.webp"
								alt={"Карта столов для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
			] as Array<{
				tabName: string

				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent: JSX.Element
			}>,
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					tabName: "Данные по персоналу",
					desktopTitle: "Кто не работает — тот в отчёт не попадает",
					desktopContent:
						"Учитывается рабочее время и эффективность каждого сотрудника.\n Зарплату можно рассчитать автоматически.",
					detailLink: pagesLinks.staff,
					mediaContent: (
						<div className={classNames(styles.image__container, styles.image__container_by)}>
							<StaticImage
								className={classNames(styles.image1, styles.image1__by)}
								src="./assets/reports-data-1_by.webp"
								alt={"Доставка для ресторанного бизнеса"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
				{
					tabName: "Уведомления",
					desktopTitle: "Больше знаешь — выше продажи",
					desktopContent:
						"Первым узнавай о самом важном: например, выручке за смену или отменах.\n По электронной почте, в мобильном приложении или прямо в Telegram.",
					detailLink: pagesLinks.notifications,
					mediaContent: (
						<div className={classNames(styles.image__container, styles.image__container_by)}>
							<StaticImage
								className={classNames(styles.image2, styles.image2__by)}
								src="./assets/reports-data-2-1_by.webp"
								alt={"Карта столов для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={styles.image3}
								src="./assets/reports-data-2-2_by.webp"
								alt={"Карта столов для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
			] as Array<{
				tabName: string

				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent: JSX.Element
			}>,
		}
	}

	return {
		features: [
			{
				tabName: "Данные по персоналу",
				desktopTitle: "Кто не работает — тот в отчёт не попадает",
				desktopContent:
					"Учитывается рабочее время и эффективность каждого сотрудника.\n Зарплату можно рассчитать автоматически.",
				detailLink: pagesLinks.staff,
				mediaContent: (
					<div className={classNames(styles.image__container, styles.image__container_by)}>
						<StaticImage
							className={classNames(styles.image1, styles.image1__by)}
							src="./assets/reports-data-1.png"
							alt={"Доставка для ресторанного бизнеса"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.featureBackground} />,
			},
			{
				tabName: "Уведомления",
				desktopTitle: "Больше знаешь — выше продажи",
				desktopContent:
					"Первым узнавай о самом важном: например, выручке за смену или отменах. Уведомления придут на электронную почту или прямо в Telegram. А аналитику кофейни можно отслеживать в мобильном приложении.",
				detailLink: pagesLinks.notifications,
				mediaContent: (
					<div className={classNames(styles.image__container, styles.image__container_by)}>
						<StaticImage
							className={classNames(styles.image2, styles.image2__by)}
							src="./assets/reports-data-2-1.png"
							alt={"Карта столов для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
						<StaticImage
							className={styles.image3}
							src="./assets/reports-data-2-2.png"
							alt={"Карта столов для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.featureBackground} />,
			},
		] as Array<{
			tabName: string

			// Заголовки могут быть для разных раскладок
			desktopTitle: string
			tabletTitle?: string
			mobileTitle?: string

			// Текстовый контент может быть для разных
			desktopContent: string
			tabletContent?: string
			mobileContent?: string

			detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

			mediaContent: JSX.Element
			backdropContent: JSX.Element
		}>,
	}
}

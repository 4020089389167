import * as React from "react"
import { SVGProps } from "react"

const MoneyBagIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<ellipse
			cx="17.9367"
			cy="11.9067"
			rx="3.26093"
			ry="2.0866"
			stroke="#7D828C"
			strokeWidth="2"
		/>
		<path
			d="M21.1973 16.4529C20.5464 17.0766 19.3299 17.4962 17.9365 17.4962C16.5432 17.4962 15.3267 17.0766 14.6758 16.4529"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M21.1973 19.9563C20.5464 20.58 19.3299 20.9996 17.9365 20.9996C16.5432 20.9996 15.3267 20.58 14.6758 19.9563"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M11.8007 21H8.6616C5.42476 21 2.80078 18.376 2.80078 15.1392V15.1392C2.80078 13.1464 3.67012 11.2528 5.18133 9.95386L8.37615 7.20773L6.25292 4.63678C5.71436 3.98465 6.1782 3 7.02397 3H11.8007H16.5802C17.4277 3 17.891 3.98801 17.349 4.6395L15.2127 7.20773"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default MoneyBagIcon

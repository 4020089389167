import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./styles.module.scss";
import classNames from "classnames";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					tabName: "Быстрая аналитика",
					desktopTitle: "Виджеты: держи руку на пульсе",
					mobileTitle: "Виджеты: держи руку \nна пульсе",
					desktopContent:
						"Понятные графики и диаграммы на главной странице подскажут, как идут дела.",
					mediaContent: (
						<Swiper
							modules={[FreeMode]}
							className={styles.feature1Media}
							grabCursor={true}
							freeMode={true}
							slidesPerView={"auto"}
							breakpoints={{
								300: {
									spaceBetween: 30,
								},
								768: {
									spaceBetween: 45,
								},
								999: {
									spaceBetween: 60,
								},
							}}
						>
							<SwiperSlide className={styles.feature1Media__slide}>
								<StaticImage
									className={styles.feature1Media__image}
									src="./assets/reports-widgets-1-1_kz.webp"
									alt={"Топ 5 блюд"}
									breakpoints={[400, 680]}
									sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
									objectFit={"contain"}
									placeholder={"blurred"}
									quality={90}
								/>
							</SwiperSlide>

							<SwiperSlide className={styles.feature1Media__slide}>
								<StaticImage
									className={styles.feature1Media__image}
									src="./assets/reports-widgets-1-2.png"
									alt={"выручка по часам"}
									breakpoints={[400, 680]}
									sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
									objectFit={"contain"}
									placeholder={"blurred"}
									quality={90}
								/>
							</SwiperSlide>

							<SwiperSlide className={styles.feature1Media__slide}>
								<StaticImage
									className={styles.feature1Media__image}
									src="./assets/reports-widgets-1-3_kz.webp"
									alt={"выручка"}
									breakpoints={[400, 680]}
									sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
									objectFit={"contain"}
									placeholder={"blurred"}
									quality={90}
								/>
							</SwiperSlide>

							<SwiperSlide
								className={classNames(
									styles.feature1Media__slide,
									styles.feature1Media__slideSmall,
								)}
							>
								<StaticImage
									className={classNames(
										styles.feature1Media__image,
										styles.feature1Media__imageSmall,
									)}
									src="./assets/reports-widgets-1-5_kz.webp"
									alt={"Средний чек"}
									breakpoints={[400, 680]}
									sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
									objectFit={"contain"}
									placeholder={"blurred"}
									quality={90}
								/>
							</SwiperSlide>
						</Swiper>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
				{
					tabName: "На любом устройстве",
					desktopTitle: "Работай, где захочешь",
					mobileTitle: 'Работай, \nгде захочешь',
					desktopContent: (
						<>
							Все виджеты можно просматривать в&nbsp;браузере с&nbsp;любого гаджета <br />
							или через мобильное приложение.
						</>
					),
					tabletContent: (
						<>
							Все виджеты можно просматривать в&nbsp;браузере с&nbsp;любого устройства или через мобильное приложение.
						</>
					),
					mobileContent: (
						<>
							Все виджеты можно просматривать в&nbsp;браузере с&nbsp;любого устройства или через мобильное приложение.
						</>
					),
					mediaContent: (
						<div className={styles.feature2Media}>
							<StaticImage
								className={classNames(styles.feature2Media__image1_kz, styles.feature_image__one)}
								src="./assets/reports-widgets-2-1_kz.png"
								alt={"Карта столов для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(styles.feature2Media__image2_kz, styles.feature_image__two)}
								src="./assets/reports-widgets-2-2_kz.png"
								alt={"Карта столов для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
			] as Array<{
				tabName: string

				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent: JSX.Element
			}>,
		};
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					tabName: "Жылдам аналитика",
					desktopTitle: "Виджеттер: ісің тынысын бақылап отырыңыз",
					desktopContent:
						"Басты беттегі түсінікті графиктер мен диаграммалар істің қалай жүріп жатқанын айтып береді.",
					mediaContent: (
						<Swiper
							modules={[FreeMode]}
							className={styles.feature1Media}
							grabCursor={true}
							freeMode={true}
							slidesPerView={"auto"}
							breakpoints={{
								300: {
									spaceBetween: 30,
								},
								768: {
									spaceBetween: 45,
								},
								999: {
									spaceBetween: 60,
								},
							}}
						>
							<SwiperSlide className={styles.feature1Media__slide}>
								<StaticImage
									className={styles.feature1Media__image}
									src="./assets/reports-widgets-1-1_kz.webp"
									alt={"Топ 5 блюд"}
									breakpoints={[400, 680]}
									sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
									objectFit={"contain"}
									placeholder={"blurred"}
									quality={90}
								/>
							</SwiperSlide>

							<SwiperSlide className={styles.feature1Media__slide}>
								<StaticImage
									className={styles.feature1Media__image}
									src="./assets/reports-widgets-1-2.png"
									alt={"выручка по часам"}
									breakpoints={[400, 680]}
									sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
									objectFit={"contain"}
									placeholder={"blurred"}
									quality={90}
								/>
							</SwiperSlide>

							<SwiperSlide className={styles.feature1Media__slide}>
								<StaticImage
									className={styles.feature1Media__image}
									src="./assets/reports-widgets-1-3_kz.webp"
									alt={"выручка"}
									breakpoints={[400, 680]}
									sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
									objectFit={"contain"}
									placeholder={"blurred"}
									quality={90}
								/>
							</SwiperSlide>

							<SwiperSlide
								className={classNames(
									styles.feature1Media__slide,
									styles.feature1Media__slideSmall,
								)}
							>
								<StaticImage
									className={classNames(
										styles.feature1Media__image,
										styles.feature1Media__imageSmall,
									)}
									src="./assets/reports-widgets-1-5_kz.webp"
									alt={"Средний чек"}
									breakpoints={[400, 680]}
									sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
									objectFit={"contain"}
									placeholder={"blurred"}
									quality={90}
								/>
							</SwiperSlide>
						</Swiper>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
				{
					tabName: "Кез келген құрылғыда",
					desktopTitle: "Қалаған жерде жұмыс істеңіз",
					mobileTitle: 'Қалаған жерде\nжұмыс істеңіз',
					desktopContent: (
						<>
							Барлық виджеттерді кез келген құрылғыдан браузерде немесе мобильді қосымша арқылы қарауға болады.
						</>
					),
					mediaContent: (
						<div className={styles.feature2Media}>
							<StaticImage
								className={classNames(styles.feature2Media__image1_kz, styles.feature_image__one)}
								src="./assets/reports-widgets-2-1_kz.png"
								alt={"Карта столов для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(styles.feature2Media__image2_kz, styles.feature_image__two)}
								src="./assets/reports-widgets-2-2_kz.png"
								alt={"Карта столов для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
			] as Array<{
				tabName: string

				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent: JSX.Element
			}>,
		};
	}

	if (locale === "ru-BY") {
		return {
			features: [
				{
					tabName: "Быстрая аналитика",
					desktopTitle: "Виджеты: держи руку на пульсе",
					mobileTitle: "Виджеты: \nдержи руку \nна пульсе",
					desktopContent:
						"Понятные графики и диаграммы на главной странице подскажут, как идут дела.",
					mediaContent: (
						<Swiper
							modules={[FreeMode]}
							className={styles.feature1Media}
							grabCursor={true}
							freeMode={true}
							slidesPerView={"auto"}
							breakpoints={{
								300: {
									spaceBetween: 30,
								},
								768: {
									spaceBetween: 45,
								},
								999: {
									spaceBetween: 60,
								},
							}}
						>
							<SwiperSlide className={styles.feature1Media__slide}>
								<StaticImage
									className={styles.feature1Media__image}
									src="./assets/reports-widgets-1-1_by.webp"
									alt={"Топ 5 блюд"}
									breakpoints={[400, 680]}
									sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
									objectFit={"contain"}
									placeholder={"blurred"}
									quality={90}
								/>
							</SwiperSlide>

							<SwiperSlide className={styles.feature1Media__slide}>
								<StaticImage
									className={styles.feature1Media__image}
									src="./assets/reports-widgets-1-2.png"
									alt={"выручка по часам"}
									breakpoints={[400, 680]}
									sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
									objectFit={"contain"}
									placeholder={"blurred"}
									quality={90}
								/>
							</SwiperSlide>

							<SwiperSlide className={styles.feature1Media__slide}>
								<StaticImage
									className={styles.feature1Media__image}
									src="./assets/reports-widgets-1-3_by.webp"
									alt={"выручка"}
									breakpoints={[400, 680]}
									sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
									objectFit={"contain"}
									placeholder={"blurred"}
									quality={90}
								/>
							</SwiperSlide>

							<SwiperSlide
								className={classNames(
									styles.feature1Media__slide,
									styles.feature1Media__slideSmall,
								)}
							>
								<StaticImage
									className={classNames(
										styles.feature1Media__image,
										styles.feature1Media__imageSmall,
									)}
									src="./assets/reports-widgets-1-5_by.webp"
									alt={"Средний чек"}
									breakpoints={[400, 680]}
									sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
									objectFit={"contain"}
									placeholder={"blurred"}
									quality={90}
								/>
							</SwiperSlide>
						</Swiper>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
				{
					tabName: "На любом устройстве",
					desktopTitle: "Работай, где захочешь",
					mobileTitle: 'Работай, \nгде захочешь',
					desktopContent: (
						<>
							Все виджеты можно просматривать в&nbsp;браузере с&nbsp;любого гаджета <br />
							или через мобильное приложение.
						</>
					),
					tabletContent: (
						<>
							Все виджеты можно просматривать в&nbsp;браузере с&nbsp;любого устройства или через мобильное приложение.
						</>
					),
					mobileContent: (
						<>
							Все виджеты можно просматривать в&nbsp;браузере с&nbsp;любого устройства или через мобильное приложение.
						</>
					),
					mediaContent: (
						<div className={styles.feature2Media}>
							<StaticImage
								className={classNames(styles.feature2Media__image1_kz, styles.feature_image__one)}
								src="./assets/reports-widgets-2-1_by.png"
								alt={"Карта столов для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(styles.feature2Media__image2_kz, styles.feature_image__two)}
								src="./assets/reports-widgets-2-2_by.png"
								alt={"Карта столов для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
			] as Array<{
				tabName: string

				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent: JSX.Element
			}>,
		};
	}

	return {
		features: [
			{
				tabName: "Быстрая аналитика",
				desktopTitle: "Виджеты: держи руку на пульсе",
				desktopContent:
					"Понятные графики и диаграммы на главной странице подскажут, как идут дела в заведении.",
				mediaContent: (
					<Swiper
						modules={[FreeMode]}
						className={styles.feature1Media}
						grabCursor={true}
						freeMode={true}
						slidesPerView={"auto"}
						breakpoints={{
							300: {
								spaceBetween: 30,
							},
							768: {
								spaceBetween: 45,
							},
							999: {
								spaceBetween: 60,
							},
						}}
					>
						<SwiperSlide className={styles.feature1Media__slide}>
							<StaticImage
								className={styles.feature1Media__image}
								src="./assets/reports-widgets-1-1.png"
								alt={"Топ 5 блюд"}
								breakpoints={[400, 680]}
								sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</SwiperSlide>

						<SwiperSlide className={styles.feature1Media__slide}>
							<StaticImage
								className={styles.feature1Media__image}
								src="./assets/reports-widgets-1-2.png"
								alt={"выручка по часам"}
								breakpoints={[400, 680]}
								sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</SwiperSlide>

						<SwiperSlide className={styles.feature1Media__slide}>
							<StaticImage
								className={styles.feature1Media__image}
								src="./assets/reports-widgets-1-3.png"
								alt={"выручка"}
								breakpoints={[400, 680]}
								sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</SwiperSlide>

						<SwiperSlide className={styles.feature1Media__slide}>
							<StaticImage
								className={styles.feature1Media__image}
								src="./assets/reports-widgets-1-4.png"
								alt={"Сегодня"}
								breakpoints={[400, 680]}
								sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</SwiperSlide>

						<SwiperSlide
							className={classNames(
								styles.feature1Media__slide,
								styles.feature1Media__slideSmall,
							)}
						>
							<StaticImage
								className={classNames(
									styles.feature1Media__image,
									styles.feature1Media__imageSmall,
								)}
								src="./assets/reports-widgets-1-5.png"
								alt={"Средний чек"}
								breakpoints={[400, 680]}
								sizes="(max-width: 500px) 400px,  (min-width: 501px) 680px"
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</SwiperSlide>
					</Swiper>
				),
				backdropContent: <div className={styles.featureBackground} />,
			},
			{
				tabName: "На любом устройстве",
				desktopTitle: "В любом месте, в любое время",
				desktopContent: (
					<>
						Все виджеты можно просматривать в браузере с любого устройства или
						через мобильное приложение. Quick&nbsp;Resto — программа аналитики и
						учёта для ресторанов и кафе, доступная в облаке.
					</>
				),
				mediaContent: (
					<div className={styles.feature2Media}>
						<StaticImage
							className={classNames(styles.feature2Media__image1, styles.feature_image_ru__one)}
							src="./assets/reports-widgets-2-1.png"
							alt={"Карта столов для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
						<StaticImage
							className={classNames(styles.feature2Media__image2, styles.feature_image_ru__two)}
							src="./assets/reports-widgets-2-2.png"
							alt={"Карта столов для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.featureBackground} />,
			},
		] as Array<{
			tabName: string

			// Заголовки могут быть для разных раскладок
			desktopTitle: string
			tabletTitle?: string
			mobileTitle?: string

			// Текстовый контент может быть для разных
			desktopContent: string
			tabletContent?: string
			mobileContent?: string

			detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

			mediaContent: JSX.Element
			backdropContent: JSX.Element
		}>,
	};
};

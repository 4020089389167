import React from "react"

import { getData } from "./data"
import FullScreenFeatures from "../../../components/_V2/FullScreenFeatures"
import { useLocalizationContext } from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function WidgetsFeatures(props: Props) {
	const localizationContext = useLocalizationContext();

	return (
		<FullScreenFeatures
			key={localizationContext.locale}
			className={props.className}
			data={getData(localizationContext.locale).features}
			transformOnAdaptive={true}
			theme={"purple"}
		/>
	)
}

import * as React from "react"
import { SVGProps } from "react"

const HonestIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_7630_5001)">
			<rect
				x="6.6377"
				y="8.98047"
				width="3.91457"
				height="2.93593"
				rx="1.46796"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinejoin="round"
			/>
			<rect
				x="13.4478"
				y="8.98047"
				width="3.91457"
				height="2.93593"
				rx="1.46796"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinejoin="round"
			/>
			<rect
				x="9.40344"
				y="14.6572"
				width="5.19317"
				height="3.1159"
				rx="1.55795"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinejoin="round"
			/>
			<path
				d="M3.13489 11.9996C3.13489 7.10357 7.10393 3.13452 12 3.13452V3.13452C16.8961 3.13452 20.8651 7.10357 20.8651 11.9996V20.8647H3.13489V11.9996Z"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_7630_5001">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0 -0.000244141)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default HonestIcon

import React from "react";
import styles from "./styles.module.scss";
import { Locales } from "../../../localization/types";

export const getAdvantages = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return [
			{
				title: "Доступно на любом смартфоне",
				description:
					(
						<p>
							Всё хранится в&nbsp;облаке&nbsp;&mdash; можно анализировать данные и&nbsp;получать уведомления
							о&nbsp;состоянии дел, находясь где угодно.
						</p>
					),
			},
			{
				title: (
					<>
						Данные <br className={styles.title} /> в безопасности
					</>
				),
				description:
					(
						<p>
							Используем распределённую сеть, которая устойчива к&nbsp;сбоям: продолжит работать, даже если один
							сервер выйдет из&nbsp;строя.
						</p>
					),
			},
			{
				title: "Сколько угодно пользователей",
				description:
					(
						<p>
							Не&nbsp;нужно покупать лицензию
							на&nbsp;каждого сотрудника. Просто подключаешь к&nbsp;общему аккаунту всех, кто пользуется бэк-офисом.
						</p>
					),
			},
		];
	}

	if (locale === "kz-KZ") {
		return [
			{
				title: "Кез келген смартфонда қолжетімді",
				description:
					(
						<p>
							Барлығы бұлтта сақталады - кез келген жерде бола отырып, деректерді талдауға және істер жағдайы туралы хабарламалар алуға болады.
						</p>
					),
			},
			{
				title: (
					<>
						Деректер <br className={styles.title} />қауіпсіз жерде
					</>
				),
				description:
					(
						<p>
							Іркілістерге төзімді таралған серверлер желісін пайдаланамыз: тіпті егер бір сервер істен
							шықса да, жұмысын жалғастырады.
						</p>
					),
			},
			{
				title: "Қанша керек болса, сонша пайдаланушы",
				description:
					(
						<p>
							Әр қызметкерге лицензияны сатып алудың керегі жоқ. Жай ғана ортақ аккаунтқа бэк-офисті пайдаланатын бәрін қосасыз.
						</p>
					),
			},
		];
	}

	if (locale === "ru-BY") {
		return [
			{
				title: "Доступно на любом смартфоне",
				description:
					(
						<>
							<p className={styles.advantagesDesktop}>
								Всё хранится в&nbsp;облаке&nbsp;&mdash; можно анализировать данные и&nbsp;получать уведомления
								о&nbsp;состоянии дел, находясь где угодно.
							</p>
							<p className={styles.advantagesTablet}>
								Всё хранится в&nbsp;облаке&nbsp;&mdash; можно анализировать данные и&nbsp;получать уведомления
								о&nbsp;состоянии дел, находясь где угодно.
							</p>
						</>
					),
			},
			{
				title: (
					<>
						Данные <br className={styles.title} /> в безопасности
					</>
				),
				description:
					(
						<>
							<p className={styles.advantagesDesktop}>
								Используем распределённую сеть, которая устойчива к&nbsp;сбоям: продолжит работать, даже если один
								сервер выйдет из&nbsp;строя.
							</p>
							<p className={styles.advantagesTablet}>
								Используем распределённую сеть, которая устойчива к&nbsp;сбоям: продолжит работать, даже если один
								сервер выйдет из&nbsp;строя.
							</p>
						</>
					),
			},
			{
				title: "Сколько угодно пользователей",
				description:
					(
						<>
							<p className={styles.advantagesDesktop}>
								Не&nbsp;нужно покупать лицензию
								на&nbsp;каждого сотрудника. Просто подключаешь к&nbsp;общему аккаунту всех, кто пользуется бэк-офисом.
							</p>
							<p className={styles.advantagesTablet}>
								Не&nbsp;нужно покупать лицензию
								на&nbsp;каждого сотрудника. Просто подключаешь к&nbsp;общему аккаунту всех, кто пользуется бэк-офисом.
							</p>
						</>
					),
			},
		];
	}

	return [
		{
			title: "Доступно на любом смартфоне",
			description:
				(
					<>
						<p className={styles.advantagesDesktop}>
							Всё хранится в облаке — можно
							анализировать данные и получать
							оперативные уведомления
							о состоянии дел, находясь где<br />
							угодно: в России или за границей.
						</p>
						<p className={styles.advantagesTablet}>Всё хранится в облаке — можно анализировать данные и получать
							оперативные уведомления о состоянии дел, находясь где угодно: в России или за границей.</p>
					</>
				),
		},
		{
			title: "Данные в безопасности",
			description:
				(
					<>
						<p className={styles.advantagesDesktop}>
							Используем распределённую сеть
							серверов, которая устойчива
							к сбоям: продолжит работать,
							даже если один сервер<br />
							выйдет из строя.
						</p>
						<p className={styles.advantagesTablet}>
							Используем распределённую сеть серверов, которая устойчива к сбоям: продолжит работать, даже если один
							сервер выйдет из строя.
						</p>
					</>
				),
		},
		{
			title: "Сколько угодно пользователей",
			description:
				(
					<>
						<p className={styles.advantagesDesktop}>
							Не нужно покупать отдельную лицензию на каждого сотрудника. Просто подключаешь к общему аккаунту всех, кто
							пользуется<br />бэк-офисом.
						</p>
						<p className={styles.advantagesTablet}>
							Не нужно покупать отдельную лицензию на каждого сотрудника. Просто подключаешь к общему аккаунту всех, кто
							пользуется бэк-офисом.
						</p>
					</>
				),
		},
	];
};

import { Locales } from "../../../localization/types";
import ogImage from "../ProductPresentation/assets/reports-presentation.png";
import ogImageKz from "../ProductPresentation/assets/reports-presentation_kz.webp";
import ogImageBy from "../ProductPresentation/assets/reports-presentation_by.webp";
import {externalLinks} from "../../../pages-data/_V2/common/links";

export const getReportstMeta = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			title: 'Настройка и автоматизация аналитики ресторана и кафе | Quick Resto Казахстан',
			metaTags: [
				{
					name: "viewport",
					content: "width=device-width, initial-scale=1, shrink-to-fit=no",
				},
				{
					name: "viewport",
					content: "width=375, maximum-scale=1.0, initial-scale=1",
				},
				{
					httpEquiv: "X-UA-Compatible",
					content: "ie=edge",
				},
				{
					name: "description",
					content:
						"Программа автоматизации аналитики кафе и ресторанов ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение под ключ. Автоматизируй бизнес в Казахстане",
				},
				{
					property: "og:description",
					content:
						"Программа автоматизации аналитики кафе и ресторанов ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение под ключ. Автоматизируй бизнес в Казахстане",
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:url",
					content: `${externalLinks.quickrestoKZ.href}reports/`,
				},
				{
					property: "og:title",
					content:
						"Настройка и автоматизация аналитики ресторана и кафе | Quick Resto Казахстан",
				},
				{
					property: "og:image",
					content: `${externalLinks.quickrestoKZ.href}${ogImageKz}`,
				},
			],
			canonical: `${externalLinks.quickrestoKZ.href}reports/`,
		}
	}

	if (locale === 'ru-BY') {
		return {
			title: 'Настройка и автоматизация аналитики ресторана и кафе | Quick Resto Беларусь',
			metaTags: [
				{
					name: "viewport",
					content: "width=device-width, initial-scale=1, shrink-to-fit=no",
				},
				{
					name: "viewport",
					content: "width=375, maximum-scale=1.0, initial-scale=1",
				},
				{
					httpEquiv: "X-UA-Compatible",
					content: "ie=edge",
				},
				{
					name: "description",
					content:
						"Программа автоматизации аналитики кафе и ресторанов ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение под ключ. Автоматизируй бизнес в Беларуси",
				},
				{
					property: "og:description",
					content:
						"Программа автоматизации аналитики кафе и ресторанов ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение под ключ. Автоматизируй бизнес в Беларуси",
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:url",
					content: `${externalLinks.quickrestoBY.href}reports/`,
				},
				{
					property: "og:title",
					content:
						"Настройка и автоматизация аналитики ресторана и кафе | Quick Resto Беларусь",
				},
				{
					property: "og:image",
					content: `${externalLinks.quickrestoBY.href}${ogImageBy}`,
				},
			],
			canonical: `${externalLinks.quickrestoBY.href}reports/`,
		}
	}

	return {
		title: 'Настройка и автоматизация аналитики ресторана и кафе | Quick Resto',
		metaTags: [
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1, shrink-to-fit=no",
			},
			{
				name: "viewport",
				content: "width=375, maximum-scale=1.0, initial-scale=1",
			},
			{
				httpEquiv: "X-UA-Compatible",
				content: "ie=edge",
			},
			{
				name: "description",
				content:
					"Программа автоматизации аналитики кафе и ресторанов  ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение  под ключ",
			},
			{
				property: "og:description",
				content:
					"Программа автоматизации аналитики кафе и ресторанов  ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение  под ключ",
			},
			{
				property: "og:type",
				content: "website",
			},
			{
				property: "og:url",
				content: `${externalLinks.quickrestoRU.href}reports/`,
			},
			{
				property: "og:title",
				content:
					"Настройка и автоматизация аналитики ресторана и кафе | Quick Resto",
			},
			{
				property: "og:image",
				content: `${externalLinks.quickrestoRU.href}${ogImage}`,
			},
		],
		canonical: `${externalLinks.quickrestoRU.href}reports/`,
	}
}

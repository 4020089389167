import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"

import NoteIcon from "../../../assets/_V2/svg/NoteIcon"
import styles from "./styles.module.scss"
import CalendarIcon from "../../../assets/_V2/svg/CalendarIcon"
import HomeIcon from "../../../assets/_V2/svg/HomeIcon"
import ChartIcon from "../../../assets/_V2/svg/ChartIcon"
import DishIcon from "../../../assets/_V2/svg/DishIcon"
import HandIcon from "../../../assets/_V2/svg/HandIcon"
import MoneyBagIcon from "../../../assets/_V2/svg/MoneyBagIcon"
import PlanIcon from "../../../assets/_V2/svg/PlanIcon"
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			title: "Ответы есть. \nСпрашивай.",
			headerPlacement: "left",
			features: [
				{
					icon: <CalendarIcon />,
					name: "По каким дням ходят постоянные гости",
					description: "Покажет, количество посещений гостей по дням недели.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-1_kz.webp"
								alt="количество посещений"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
								Сводная таблица по количеству посещений
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Покажет, количество посещений гостей по дням недели.
							</div>
						</div>
					),
				},
				{
					icon: <HomeIcon />,
					name: "Какая у меня рентабельность",
					description: "Чем ниже фудкост, тем выше прибыль.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-2_kz.webp"
								alt="продажи и фудкост"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
								Сводная таблица по продажам и фудкосту
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Чем ниже <br />
								фудкост, тем <br />
								выше прибыль.
							</div>
						</div>
					),
				},
				{
					icon: <ChartIcon />,
					name: "Какие акции «пошли в народ»",
					description: "Отчёт покажет какая акция работает лучше всего.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-3_kz.webp"
								alt="количество скидок"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
								Сводная таблица по количеству скидок
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Отчёт покажет какая <br />
								акция работает <br />
								лучше всего.
							</div>
						</div>
					),
				},
				{
					icon: <DishIcon />,
					name: "Как сократить возвраты блюд",
					description: "Количество возвратов относительно времени приготовления.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-4_kz.webp"
								alt="время приготовления"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
								Сводная таблица по времени приготовления
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Количество возвратов <br />
								относительно времени <br />
								приготовления.
							</div>
						</div>
					),
				},
				{
					icon: <HandIcon />,
					name: "Где недополученная прибыль",
					description: "Найди сотрудника, который проводит махинации с бонусной программой.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-5_kz.webp"
								alt="количество авторизаций"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
								Сводная таблица по количеству авторизаций
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Найди сотрудника, <br />
								который проводит <br />
								махинации с бонусной <br />
								программой.
							</div>
						</div>
					),
				},
				{
					icon: <MoneyBagIcon />,
					name: "Сколько приносит прибыли каждый цех",
					description: "Кто зарабатывает больше кухня, бар или кондитерка",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-6_kz.webp"
								alt="продажи по цехам"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
								Сводная таблица продаж по цехам
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Кто зарабатывает <br />
								больше кухня, бар <br />
								или кондитерка
							</div>
						</div>
					),
				},
				{
					icon: <PlanIcon />,
					name: "Как составить план закупок на период",
					description: "Проанализируй расход и спрогнозируй закупки",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-7_kz.webp"
								alt="расход продуктов за период"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
								Сводная таблица расхода продуктов за период
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Проанализируй расход<br/>
								и спрогнозируй<br/>
								закупки
							</div>
						</div>
					),
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			title: "Жауаптар бар.\nСұраңыз.",
			headerPlacement: "left",
			features: [
				{
					icon: <CalendarIcon />,
					name: "Тұрақты қонақтар қай күндері келіп тұрады",
					description: "Қонақтардың келулері санын апта күндері бойынша көрсетеді.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-1_kz.webp"
								alt="количество посещений"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
								Есептер генераторы - келулер саны бойынша жиынтық кесте
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Қонақтардың келулері санын апта күндері бойынша көрсетеді.
							</div>
						</div>
					),
				},
				{
					icon: <HomeIcon />,
					name: "Менің табыстылығым қандай",
					description: "Фудкост неғұрлым төмен болса, пайда соғұрлым жоғары болады.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-2_kz.webp"
								alt="продажи и фудкост"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
								Есептер генераторы - сатулар және фудкост бойынша жиынтық кесте
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Фудкост неғұрлым төмен болса, пайда соғұрлым жоғары болады.
							</div>
						</div>
					),
				},
				{
					icon: <ChartIcon />,
					name: "Қандай акциялар жұртқа ұнады",
					description: "Есеп ең жақсы жұмыс істейтін акцияны көрсетеді.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-3_kz.webp"
								alt="количество скидок"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
								Есептер генераторы - жеңілдіктер саны бойынша жиынтық кесте
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Есеп ең жақсы жұмыс істейтін акцияны көрсетеді.
							</div>
						</div>
					),
				},
				{
					icon: <DishIcon />,
					name: "Тағамдарды қайтаруды қалай қысқартуға болады",
					description: "Дайындау уақытына қатысты қайтарулар саны.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-4_kz.webp"
								alt="время приготовления"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
								Есептер генераторы - дайындау уақыты бойынша жиынтық кесте
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Дайындау уақытына қатысты қайтарулар саны.
							</div>
						</div>
					),
				},
				{
					icon: <HandIcon />,
					name: "Түгел алынбаған пайда қайда",
					description: "Бонустық бағдарламамен айла-шарғы жүргізетін қызметті табыңыз.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-5_kz.webp"
								alt="количество авторизаций"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
								Есептер генераторы - авторизациялар саны бойынша жиынтық кесте
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Бонустық бағдарламамен айла-шарғы жүргізетін қызметті табыңыз.
							</div>
						</div>
					),
				},
				{
					icon: <MoneyBagIcon />,
					name: "Әр цех қанша пайда әкеліп жатыр",
					description: "Қайсысы көбірек ақша табады: асүй, бар немесе кондитерлік цеха па",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-6_kz.webp"
								alt="продажи по цехам"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
								Есептер генераторы - цехтар бойынша жиынтық кесте
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Қайсысы көбірек ақша табады: асүй, бар немесе кондитерлік цеха па
							</div>
						</div>
					),
				},
				{
					icon: <PlanIcon />,
					name: "Кезеңге сатып алу жоспарын қалай жасауға болады",
					description: "Шығысты талдаңыз да, сатып алуды болжаңыз",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-7_kz.webp"
								alt="расход продуктов за период"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
								Есептер генераторы - кезең ішінде өнімдер шығысы бойынша жиынтық кесте
							</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Шығысты талдаңыз да, сатып алуды болжаңыз
							</div>
						</div>
					),
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			title: "Ответы есть. \nСпрашивай.",
			headerPlacement: "left",
			features: [
				{
					icon: <CalendarIcon />,
					name: "По каким дням ходят постоянные гости",
					description: "Покажет количество посещений гостей по дням недели.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-1_by.webp"
								alt="количество посещений"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Генератор отчетов — сводная таблица по количеству посещений
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Покажет количество посещений гостей по дням недели.
							</div>
						</div>
					),
				},
				{
					icon: <HomeIcon />,
					name: "Какая у меня рентабельность",
					description: "Чем ниже фудкост, тем выше прибыль.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-2_by.webp"
								alt="продажи и фудкост"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Генератор отчетов — сводная таблица по продажам и фудкосту
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Чем ниже <br />
								фудкост, тем <br />
								выше прибыль.
							</div>
						</div>
					),
				},
				{
					icon: <ChartIcon />,
					name: "Какие акции «пошли в народ»",
					description: "Отчёт покажет какая акция работает лучше всего.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-3_by.webp"
								alt="количество скидок"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Генератор отчетов — сводная таблица по количеству скидок
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Отчёт покажет какая <br />
								акция работает <br />
								лучше всего.
							</div>
						</div>
					),
				},
				{
					icon: <DishIcon />,
					name: "Как сократить возвраты блюд",
					description: "Количество возвратов относительно времени приготовления.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-4_by.webp"
								alt="время приготовления"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Генератор отчетов — сводная таблица по времени приготовления
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Количество возвратов <br />
								относительно времени <br />
								приготовления.
							</div>
						</div>
					),
				},
				{
					icon: <HandIcon />,
					name: "Где недополученная прибыль",
					description: "Найди сотрудника, который проводит махинации с бонусной программой.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-5_by.webp"
								alt="количество авторизаций"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Генератор отчетов — сводная таблица по количеству авторизаций
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Найди сотрудника, <br />
								который проводит <br />
								махинации с бонусной <br />
								программой.
							</div>
						</div>
					),
				},
				{
					icon: <MoneyBagIcon />,
					name: "Сколько прибыли приносит каждый цех",
					description: "Кто зарабатывает больше: кухня, бар или кондитерка",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-6_by.webp"
								alt="продажи по цехам"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Генератор отчетов — сводная таблица продаж по цехам
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Кто зарабатывает <br />
								больше: кухня, бар <br />
								или кондитерка
							</div>
						</div>
					),
				},
				{
					icon: <PlanIcon />,
					name: "Как составить план закупок на период",
					description: "Проанализируй расход и спрогнозируй закупки",
					mediaContent: (
						<>
							<StaticImage
								className={styles.understandingFeatures__image}
								src="./assets/reports-7_by.webp"
								alt="расход продуктов за период"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
							<span className={styles.understandingFeatures__label}>
						Генератор отчетов — сводная таблица расхода продуктов за период
					</span>
						</>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Проанализируй расход<br/>
								и спрогнозируй<br/>
								закупки
							</div>
						</div>
					),
				},
			],
		}
	}

	return {
		title: "Отчёты по любым вопросам",
		description: "С онлайн-системой учёта понятно:",
		headerPlacement: "left",
		features: [
			{
				icon: <CalendarIcon />,
				name: "По каким дням ходят постоянные гости",
				description: "Покажет, количество посещений гостей по дням недели.",
				mediaContent: (
					<>
						<StaticImage
							className={styles.understandingFeatures__image}
							src="./assets/reports-1.png"
							alt="количество посещений"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<span className={styles.understandingFeatures__label}>
						Генератор отчетов — сводная таблица по количеству посещений
					</span>
					</>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Покажет, количество посещений гостей по дням недели.
						</div>
					</div>
				),
			},
			{
				icon: <HomeIcon />,
				name: "Какая у меня рентабельность",
				description: "Чем ниже фудкост, тем выше прибыль.",
				mediaContent: (
					<>
						<StaticImage
							className={styles.understandingFeatures__image}
							src="./assets/reports-2.png"
							alt="продажи и фудкост"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<span className={styles.understandingFeatures__label}>
						Генератор отчетов — сводная таблица по продажам и фудкосту
					</span>
					</>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Чем ниже <br />
							фудкост, тем <br />
							выше прибыль.
						</div>
					</div>
				),
			},
			{
				icon: <ChartIcon />,
				name: "Какие акции «пошли в народ»",
				description: "Отчёт покажет какая акция работает лучше всего.",
				mediaContent: (
					<>
						<StaticImage
							className={styles.understandingFeatures__image}
							src="./assets/reports-3.png"
							alt="количество скидок"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<span className={styles.understandingFeatures__label}>
						Генератор отчетов — сводная таблица по количеству скидок
					</span>
					</>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Отчёт покажет какая <br />
							акция работает <br />
							лучше всего.
						</div>
					</div>
				),
			},
			{
				icon: <DishIcon />,
				name: "Как сократить возвраты блюд",
				description: "Количество возвратов относительно времени приготовления.",
				mediaContent: (
					<>
						<StaticImage
							className={styles.understandingFeatures__image}
							src="./assets/reports-4.png"
							alt="время приготовления"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<span className={styles.understandingFeatures__label}>
						Генератор отчетов — сводная таблица по времени приготовления
					</span>
					</>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Количество возвратов <br />
							относительно времени <br />
							приготовления.
						</div>
					</div>
				),
			},
			{
				icon: <HandIcon />,
				name: "Где недополученная прибыль",
				description: "Найди сотрудника, который проводит махинации с бонусной программой.",
				mediaContent: (
					<>
						<StaticImage
							className={styles.understandingFeatures__image}
							src="./assets/reports-5.png"
							alt="количество авторизаций"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<span className={styles.understandingFeatures__label}>
						Генератор отчетов — сводная таблица по количеству авторизаций
					</span>
					</>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Найди сотрудника, <br />
							который проводит <br />
							махинации с бонусной <br />
							программой.
						</div>
					</div>
				),
			},
			{
				icon: <MoneyBagIcon />,
				name: "Сколько приносит прибыли каждый цех",
				description: "Кто зарабатывает больше кухня, бар или кондитерка",
				mediaContent: (
					<>
						<StaticImage
							className={styles.understandingFeatures__image}
							src="./assets/reports-6.png"
							alt="продажи по цехам"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<span className={styles.understandingFeatures__label}>
						Генератор отчетов — сводная таблица продаж по цехам
					</span>
					</>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Кто зарабатывает <br />
							больше кухня, бар <br />
							или кондитерка
						</div>
					</div>
				),
			},
			{
				icon: <PlanIcon />,
				name: "Как составить план закупок на период",
				description: "Проанализируй расход и спрогнозируй закупки",
				mediaContent: (
					<>
						<StaticImage
							className={styles.understandingFeatures__image}
							src="./assets/reports-7.png"
							alt="расход продуктов за период"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<span className={styles.understandingFeatures__label}>
						Генератор отчетов — сводная таблица расхода продуктов за период
					</span>
					</>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Проанализируй расход<br/>
							и спрогнозируй<br/>
							закупки
						</div>
					</div>
				),
			},
		],
	}
}
